export const BACKEND_BASE_URL = "https://backend-dev.dmxlogistics.com.ng";
// URLS
// LOCAL: http://localhost:9000
// DEV: https://backend-dev.dmxlogistics.com.ng
// LIVE: https://backend.dmxlogistics.com.ng

export const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
